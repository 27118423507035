import storage from '@/utils/localStorage'
export default {
  namespaced: true,
  state: {
    token: '',
    userInfo: {}, // 用户信息（登录、注册获取）
  },
  getters: {
    userInfo: state => {
      return state.userInfo && state.userInfo.token ? state.userInfo : storage.getItem('userInfo') || {}
    },
    token: state => state.token || storage.getItem('token') || state.userInfo.token || storage.getItem('userInfo').token || '',
  },
  mutations: {
    updateUserInfo(state, result) {
      if(!result) {
        state.token = '';
        state.userInfo = {};
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        return;
      }
      console.log('3333', result)
      storage.setItem('token', result.token == null ? 'defaulttoken': result.token);
      storage.setItem('userInfo', result);
      state.token = result.token;
      state.userInfo = result;
    }
  }
}