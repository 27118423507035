
const commonPlugin = store => {
  // // 当 store 初始化后调用

  // 每次 mutation 之后调用
  store.subscribe((mutation, state) => {
    // console.log(mutation.type)
    // switch (mutation.type) {
    //   case '':
    //     break
    //   default:
    //     break
    // }
  })
}
export default commonPlugin
