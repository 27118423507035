
/**
 * 格式化金额
 * @param {Number} number 需要格式化的数字
 * @param {String} unit 后缀
 * @param {Number} fixedNum 保留几位小数(四舍五入)
 * @return {Object}
 * @example ```js
      formatMoney(2000000000, '元') // return {num: 20, postfix: '亿元'}
    ```
 */
export function formatMoney (number, unit = '', fixedNum) {
  if (number === null || number === undefined) {
    return undefined
  }
  let num = Math.abs(number)
  let dim = ''
  if (num === Infinity || isNaN(num)) {
    return undefined
  }
  if (num >= 100000000) {
    num = num / 100000000
    dim = '亿'
  } else if (num >= 10000) {
    num = num / 10000
    dim = '万'
  }
  if (typeof fixedNum === 'number' && fixedNum >= 0) {
    num = +num.toFixed(fixedNum)
  }
  return {
    num,
    postfix: `${dim}${unit}`
  }
}

/**
 * 格式化日期
 * @param {Date} date 日期对象
 * @param {String} format 格式字符串
 */
export function formatTime (date, format = 'YYYY-MM-DD hh:mm:ss') {
  date = new Date(date)
  const o = {
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  };
  if (/(Y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}
