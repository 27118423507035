import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
// import '@/utils/flexible'
import 'leaflet/dist/leaflet.css'
import 'leaflet-ant-path'
import '@/plugins'

import * as L from 'leaflet'

import * as echarts from 'echarts';
import 'echarts-wordcloud';

Vue.prototype.$L = L

Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
