export default {
  namespaced: true,
  state: {
    /** 隐藏Header */
    hiddenHeader: false,
    /** 天气数据 */
    weatherData: {}
  },
  mutations: {
    setHeaderHiddenStatus (state, status) {
      state.hiddenHeader = status
    },
    setWeatherData (state, data) {
      state.weatherData = data
    }
  },
  actions: {
  }
}
