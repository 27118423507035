let storage = {
    setItem(name, data) {
        if(Object.prototype.toString.call(data) == "[object Object]") {
            data.time = new Date().getTime();
            localStorage.setItem(name, JSON.stringify(data));
            return;
        }
        
        localStorage.setItem(name, data);
    },
    getItem(name) {
        let value = localStorage.getItem(name);
        if(!value) return '';
        if(value.indexOf("{") != -1 && value.indexOf("}") != -1) {
            let obj = JSON.parse(localStorage.getItem(name));
            if (!obj || JSON.stringify(obj) == '{}' || !obj.time) return {};
            let valid = obj.time * 24 * 60 * 60 * 1000, now = new Date().getTime();
            if (valid < now) return {}; // 24小时有效期

            return obj;
        }
        return value;
    }
}

export default storage;