import Vue from 'vue'
import 'echarts-liquidfill'
// 引入基本模板
const echarts = require('echarts/lib/echarts')
// 引入折线图等组件
require('echarts/lib/chart/line')
require('echarts/lib/chart/bar')
require('echarts/lib/chart/pie')
require('echarts/lib/chart/radar')

// 引入提示框和title组件，图例
require('echarts/lib/component/grid')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/legend')
require('echarts/lib/component/dataZoom')
require('echarts/lib/component/graphic')
require('echarts/lib/component/visualMap')
require('echarts/lib/component/markLine')
// require('echarts/lib/component/legendScroll')//图例翻译滚动

Vue.prototype.$echarts = echarts
