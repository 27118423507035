import CryptoJS from 'crypto-js'
 
// 加密函数
export function encryptData(data) {
    const KEY = CryptoJS.enc.Utf8.parse('36FC4124CF9FJ070')//后端提供的key
    const IV = CryptoJS.enc.Utf8.parse('jkOh3dYoRdfAToh2')//后端提供的iv
    // console.log(data)
    const encrypted = CryptoJS.AES.encrypt(
        data,
        KEY,
        {
            iv: IV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    )
    return encrypted.toString(CryptoJS.enc.Utf8)
}
 
// 解密函数
export function decryptData(data) {
    const KEY = CryptoJS.enc.Utf8.parse('36FC4124CF9FJ070')
    const IV = CryptoJS.enc.Utf8.parse('jkOh3dYoRdfAToh2')
    const decrypted = CryptoJS.AES.decrypt(
        data,
        KEY,
        {
            iv: IV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    )
    return decrypted.toString(CryptoJS.enc.Utf8)
}
 
export default {
    encryptData,
    decryptData
}