// 不加密的请求方式
import axios from "axios";
import { baseUrl } from "./baseUrl";
import router from "../router";
import { Message } from "element-ui";
/**
 * 判断当前是否为生产环境
 */
const isProduction = process.env.NODE_ENV === "production";

/** baseURL */
const baseURL = baseUrl

/** 文件上传路径 */
// export const uploadUrl = `${baseURL}/design/oss/file`

// axios默认配置
// 超时时间
axios.defaults.timeout = 1000 * 600;
// 默认地址
axios.defaults.baseURL = baseURL;

// http request 拦截器
// app_code: 'industry',  用来区分产业大脑和阳明心学gpt
axios.interceptors.request.use(
  (config) => {
    const userInfo = localStorage.getItem("token");
    // if (userInfo) {
    config.headers['Authorization'] = userInfo
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept'
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // console.log(config)
    if (config.data && config.data.token) config.headers.token = config.data.token
    // // }
    // if (config.method === "post") {
    //   console.log(config.data)
    //   config.data = {
    //     // app_code: 'industry',
    //     ...config.data,
    //   };
    // } else if (config.method === "get") {
    //   config.params = {
    //     // app_code: 'industry',
    //     ...config.params,
    //   };
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.error_code === 200 || response.data.code === 200) {
        console.log('back', response)
        if (response.headers.refreshToken) {
          localStorage.setItem('token', response.headers.refreshToken)
        }
        // localStorage.setItem('token', )
        return Promise.resolve(response.data);
      } else if (response?.data?.code === 401) {
        Message.error('登录信息失效！请重新登录')
        router.push({ name: "Login" });
      } else {
        return Promise.resolve(response.data);
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (!window.navigator.onLine) {
      error = {
        errCode: 0,
        message: "暂无网络",
      };
    }
    return Promise.reject(error.response || error); // 返回接口返回的错误信息
  }
);

export default axios;
