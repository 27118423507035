<template>
  <div id="app">
    <meta name="referrer" content="never" /> <!--可以让img标签预加载网络图片-->
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'App',
  created() {
  },
  mounted() {
    this.saveAreaList()
    this.saveAtlasIndestryList()
  },
  methods: {
    ...mapActions('industryMap', ['saveAreaList']),
    ...mapActions('atlasMap', ['saveAtlasIndestryList'])
  }
}
</script>

<style lang="less">
@import '~@/styles/index.less';

#app {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  // min-width: 1366px;/*no*/
  // min-height: 768px;/*no*/
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-size: 12px;
}
</style>
