export * from './format'

/**
 * 防抖
 * @param {Function} func
 * @param {number} wait 时间间隔(毫秒)
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce (func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/** 是否ipad */
export function judgeIsIpad () {
  var ua = navigator.userAgent.toLowerCase()
  if (/ipad/i.test(ua)) {
    return true
  } else {
    return false
  }
}

/**
 * hex转rgba
 * @param {String} hex hex颜色
 * @param {Number} opacity [0,1]
 * @returns {String}
 */
export function colorHexToRgba (hex, opacity = 1) {
  if (!/^#[0-9A-F]{6}$/i.test(hex)) {
    return hex
  }
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  const a = Math.max(Math.min(opacity, 1), 0)
  const res = 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')'
  return res
}

// 校验移动电话
export function checkMobile (mobile) {
  const reg = /^1(3|4|5|6|7|8|9)\d{9}$/
  if (!reg.test(mobile)) {
    return false
  }
  return true
}