// 接口
import axios from '@/axios'
import { baseUrl } from '@/axios/baseUrl'
// 发送验证码
export const sendCode = (phone) => axios.post(baseUrl + '/api/main/sendCode', phone)
// 手机登录
export const phoneLogin = (params) => axios.post(baseUrl + '/api/main/phoneLogin', params)
//修改用户信息
export const editUserInfo = (params) => axios.post(baseUrl + '/api/user/editUserInfo', params)

    //后台用户列表
export const getmaguserlist = (params) => axios.post(baseUrl + '/api/user/userPage', params)
        //后台用户新增
export const addmaguser = (params) => axios.post(baseUrl + '/api/user/createUser', params)
        //后台用户修改
export const editmaguser = (params) => axios.post(baseUrl + '/api/user/editUser', params)
            //后台用户单个查询
export const getuserbyid = (params) => axios.post(baseUrl + '/api/user/getUserInfoById', params)
//免登陆接口
export const nocodelogin = (params) => axios.post(baseUrl + '/queapi/xzy/phoneNoCodeLogin', params)



