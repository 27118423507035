import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@views/layout/index.vue";
import { decryptData } from "@/utils/AES.js";
import qs from "qs"
import { nocodelogin } from "@/api/user";
import storage from '@/utils/localStorage'
import store from "@/store";
Vue.use(VueRouter);

const NotFound = () => import("@/views/404");

const routes = [
  { path: "/", name: "home", redirect: { name: "Home" } }, // 默认页面
  { path: "/login", name: "Login", component: () => import("@views/Login/index.vue")}, // 默认页面
  { path: "/experience", name: "experience", component: () => import("@views/experience.vue") },
  { path: "/usermag", name: "usermag", component: () => import("@views/usermag.vue") },
  { path: "/role", name: "role", component: () => import("@views/Login/role.vue")},
  { path: "/wxpay", name: "wxpay", component: () => import("@views/Pay/wxpaycode.vue") },
  { path: "/payresult", name: "payresult", component: () => import("@views/Pay/payResult.vue") },
  {
    path: "/",
    name: "Home",
    component: Layout,
    redirect: { name: "Home" },
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@views/Home/index.vue"),
      },

    ],
  },

  // 404
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  const query = to.query;
  if (query.sign) {
    const signtime = decryptData(decodeURIComponent(query.sign.replaceAll(/ /g, "+")))
    const difftime = (new Date().getTime() - signtime) / 86400000
    console.log('diff', signtime, difftime)
    if (difftime < 1) {
      const params = qs.stringify({
        phone: '15511818628'
      })
      nocodelogin(params).then(res => {
        console.log('登录--', store)
        if (res.code !== 200) return this.$message.error(res.msg || '登录失败，请稍后再试~')
        store.dispatch('user/token', res.data.token)
        store.dispatch('user/userInfo', res.data)
        storage.setItem('token', res.data.token)
        storage.setItem('userInfo', res.data)
        setTimeout(() => {
          next({
            path: '/home?type=' + query.type
          })
        }, 100)
      })
    } else {
      next({
        path: '/login'
        // query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }

   
  }
  if (to.name.requireAuth) { // 判断该路由是否需要登录权限
    if (token) { // 通过vuex state获取当前的token是否存在
      next()
    } else if (params.sign) {
      
      // const param = JSON.parse(decryptData(params.sign.replace(new RegExp(/( )/g), '+')))
      // console.log(param)
      // localStorage.setItem('userInfo', JSON.stringify({
      //   "id": 2,
      //   "phone": param.id,
      //   "wx_openid": null,
      //   "password": null,
      //   "head": null,
      //   "nickname": 'AI智能鼠标',
      //   "vip_time": null,
      //   "update_time": new Date().getTime(),
      //   "create_time": new Date().getTime(),
      //   "token": "b9fd8b354a6238afe3b08c392f1b3f5bfadfad",
      //   "industry_code": null,
      //   "industry": null,
      //   "time": new Date().getTime()
      // }));
      next({
        path: '/home'
      })
    } else {
      next({
        path: '/login'
        // query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
})
export default router;
